<template>
	<div class="pui-form">
		<pollutionalerts-modals :modelName="modelName"></pollutionalerts-modals>
		<pui-form-header v-if="modelLoaded" :showHeader="!isCreatingElement">
			<pollutionalerts-form-header :modelPk="modelPk"></pollutionalerts-form-header>
		</pui-form-header>
		<v-form class="mb-4 pb-4" ref="form" v-model="valid" lazy-validation @submit.prevent v-if="modelLoaded">
			<v-tabs v-model="tabmodel" class="ml-3 mb-3" slider-color="primary">
				<v-tab :key="0" :href="'#maintab'">{{ $t('pollutionalerts.tabs.maintab') }}</v-tab>
				<v-tab v-show="!isCreatingElement && this.model.missionreport != null" :key="4" :href="'#missionreport'">{{
					$t('pollutionalerts.tabs.missionreport')
				}}</v-tab>
				<v-tab v-show="!isCreatingElement && this.model.incidentreport != null" :key="5" :href="'#incidentreport'">{{
					$t('pollutionalerts.tabs.incidentreport')
				}}</v-tab>
			</v-tabs>

			<v-tabs-items v-model="tabmodel">
				<v-tab-item :key="0" :value="'maintab'">
					<v-row class="pui-form-layout">
						<v-col cols="12">
							<v-row dense>
								<!-- TIERCODE -->
								<v-col cols="3">
									<pui-select
										:id="`tiercode-pollutionalerts`"
										:attach="`tiercode-pollutionalerts`"
										:label="$t('pollutionalerts.tiercode')"
										:disabled="formDisabled"
										required
										toplabel
										clearable
										modelName="vluppollutionalertstiers"
										v-model="model"
										reactive
										:itemsToSelect="tiercodeItemsToSelect"
										:modelFormMapping="{ tiercode: 'tiercode' }"
										:itemValue="['tiercode']"
										:itemText="(item) => `${item.tierdesc}`"
									></pui-select>
								</v-col>
								<!-- DATE -->
								<v-col cols="3">
									<pui-date-field
										:id="`date-pollutionalerts`"
										v-model="model.date"
										:label="$t('pollutionalerts.date')"
										:disabled="formDisabled"
										required
										toplabel
										time
									></pui-date-field>
								</v-col>
								<!-- DATECLOSURE -->
								<v-col cols="3" v-show="model.dateclosure != null">
									<pui-date-field
										:id="`dateclosure-pollutionalerts`"
										v-model="model.dateclosure"
										:label="$t('pollutionalerts.dateclosure')"
										disabled
										toplabel
										time
									></pui-date-field>
								</v-col>
							</v-row>
						</v-col>
						<v-col cols="12">
							<pui-field-set :title="$t('distressalerts.shipinfo')">
								<v-row dense>
									<v-col class="col-12 col-xs-12 col-sm-6 col-md-3 col-lg-3 col-xl-3">
										<pui-select
											:id="`shipid-${modelName}`"
											:attach="`shipid-${modelName}`"
											:label="$t('distressalerts.shipid')"
											toplabel
											clearable
											reactive
											:disabled="this.model.statuscode == 'C'"
											v-model="model"
											modelName="mships"
											:itemsToSelect="itemsToSelectShipid"
											:modelFormMapping="{ shipid: 'shipid', version: 'version' }"
											:itemValue="['shipid', 'version']"
											:itemText="(item) => `${item.imo} - ${item.shipname}`"
											:order="{ shipname: 'asc' }"
											showOnlyActives
											:fixedFilter="filteractiveShips"
											@change="onChangeShipId($event)"
										></pui-select>
									</v-col>
								</v-row>
								<v-row dense v-if="this.model.shipid != null">
									<!-- VESSELNAME -->
									<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
										<pui-select
											:id="`vesselname-${modelName}`"
											:attach="`vesselname-${modelName}`"
											:label="$t('distressalerts.vesselname')"
											toplabel
											clearable
											reactive
											disabled
											v-model="model"
											modelName="mships"
											:itemsToSelect="itemsToSelectShipid"
											:modelFormMapping="{ shipid: 'shipid', version: 'shipversion' }"
											:itemValue="['shipid', 'version']"
											:itemText="(item) => `${item.shipname}`"
											:key="shipKey"
										></pui-select>
									</v-col>

									<!-- IMO -->
									<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
										<pui-select
											:id="`imo-${modelName}`"
											:attach="`imo-${modelName}`"
											:label="$t('distressalerts.imo')"
											toplabel
											clearable
											reactive
											disabled
											v-model="model"
											modelName="mships"
											:itemsToSelect="itemsToSelectShipid"
											:modelFormMapping="{ shipid: 'shipid', version: 'shipversion' }"
											:itemValue="['shipid', 'version']"
											:itemText="(item) => `${item.imo}`"
											:key="shipKey"
										></pui-select>
									</v-col>
									<!-- callsign -->
									<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
										<pui-select
											:id="`callsign-${modelName}`"
											:attach="`callsign-${modelName}`"
											:label="$t('distressalerts.callsign')"
											toplabel
											clearable
											reactive
											disabled
											v-model="model"
											modelName="mships"
											:itemsToSelect="itemsToSelectShipid"
											:modelFormMapping="{ shipid: 'shipid', version: 'shipversion' }"
											:itemValue="['shipid', 'version']"
											:itemText="(item) => `${item.callsign}`"
											:key="shipKey"
										></pui-select>
									</v-col>
									<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
										<pui-select
											:id="`flag-${modelName}`"
											:attach="`flag-${modelName}`"
											:label="$t('distressalerts.flag')"
											toplabel
											clearable
											reactive
											disabled
											v-model="model"
											modelName="mships"
											:itemsToSelect="itemsToSelectShipid"
											:modelFormMapping="{ shipid: 'shipid', version: 'shipversion' }"
											:itemValue="['shipid', 'version']"
											:itemText="(item) => `${item.flag} - ${item.flagdesc} `"
											:key="shipKey"
										></pui-select>
									</v-col>
								</v-row>
								<v-row dense v-else>
									<!-- VESSELNAME -->
									<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
										<pui-text-field
											:id="`vesselname-pollutionalerts`"
											v-model="model.vesselname"
											:label="$t('distressalerts.vesselname')"
											:disabled="this.model.statuscode == 'C'"
											required
											toplabel
											maxlength="100"
										></pui-text-field>
									</v-col>
									<!-- IMO -->
									<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
										<pui-text-field
											:id="`imo-pollutionalerts`"
											required
											v-model="model.imo"
											:label="$t('distressalerts.imo')"
											:disabled="this.model.statuscode == 'C'"
											toplabel
											maxlength="7"
										></pui-text-field>
									</v-col>
									<!-- CALLSIGN -->
									<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
										<pui-text-field
											:id="`callsign-pollutionalerts`"
											v-model="model.callsign"
											:label="$t('distressalerts.callsign')"
											:disabled="this.model.statuscode == 'C'"
											required
											toplabel
											maxlength="7"
										></pui-text-field>
									</v-col>
									<!-- FLAG -->
									<v-col class="col-12 col-xs-12 col-sm-12col-md-3 col-lg-3 col-xl-3">
										<pui-select
											:id="`flag-pollutionalerts`"
											:attach="`flag-pollutionalerts`"
											:label="$t('distressalerts.flag')"
											:disabled="this.model.statuscode == 'C'"
											required
											toplabel
											clearable
											modelName="mcountries"
											v-model="model"
											reactive
											:itemsToSelect="flagItemsToSelect"
											:modelFormMapping="{ countrycode: 'flag' }"
											:itemValue="['countrycode']"
											:itemText="(item) => `${item.countrycode} - ${item.countrydesc}`"
										></pui-select>
									</v-col>
								</v-row>
							</pui-field-set>
						</v-col>
						<v-col>
							<pui-field-set
								:title="$t('distressalerts.notifyto')"
								style="margin-top: 15px; margin-bottom: 15px"
								v-if="isCreatingElement"
							>
								<v-row dense>
									<v-col cols="2">
										<pui-checkbox
											:id="`checkbox-notifyvtso-${modelName}`"
											:label="$t('pollutionalerts.notifyvtso')"
											v-model="model.notifyvtso"
											true-value="1"
											false-value="0"
										></pui-checkbox
									></v-col>
									<v-col cols="2">
										<pui-checkbox
											:id="`checkbox-notifyhama-${modelName}`"
											:label="$t('pollutionalerts.notifyhama')"
											v-model="model.notifyhama"
											true-value="1"
											false-value="0"
										></pui-checkbox
									></v-col>
									<v-col cols="2">
										<pui-checkbox
											:id="`checkbox-notifyjmoc-${modelName}`"
											:label="$t('pollutionalerts.notifyjmoc')"
											v-model="model.notifyjmoc"
											true-value="1"
											false-value="0"
										></pui-checkbox
									></v-col>
									<v-col cols="2">
										<pui-checkbox
											:id="`checkbox-harbour-master-${modelName}`"
											:label="$t('pollutionalerts.notifycgof')"
											v-model="model.notifycgof"
											true-value="1"
											false-value="0"
										></pui-checkbox
									></v-col>
									<v-col cols="2">
										<pui-checkbox
											:id="`checkbox-notifybsco-${modelName}`"
											:label="$t('pollutionalerts.notifybsco')"
											v-model="model.notifybsco"
											true-value="1"
											false-value="0"
										></pui-checkbox
									></v-col>
								</v-row>
							</pui-field-set>
						</v-col>
						<v-col cols="12">
							<v-tabs v-model="tabmodel1" class="ml-3 mb-3" slider-color="primary">
								<v-tab :key="1" :href="'#polwarn'">{{ $t('pollutionalerts.tabs.polwarn') }}</v-tab>
								<v-tab :key="2" :href="'#polnif'">{{ $t('pollutionalerts.tabs.polnif') }}</v-tab>
								<v-tab :key="3" :href="'#polfac'">{{ $t('pollutionalerts.tabs.polfac') }}</v-tab>
							</v-tabs>
							<v-tabs-items v-model="tabmodel1">
								<v-tab-item :key="1" :value="'polwarn'">
									<v-row class="pui-form-layout">
										<v-col cols="12">
											<pui-field-set :title="$t('pollutionalerts.fieldset.polwarn')">
												<v-row dense>
													<!-- PWIDENTIFICATION -->
													<v-col cols="3">
														<pui-text-field
															:id="`pwidentification-pollutionalerts`"
															v-model="model.pwidentification"
															:label="$t('pollutionalerts.pwidentification')"
															:disabled="formDisabled"
															toplabel
															maxlength="100"
														></pui-text-field>
													</v-col>
												</v-row>
												<v-row dense>
													<!-- PWFROM -->
													<v-col cols="3">
														<pui-select
															:id="`pwfrom-pollutionalerts`"
															:attach="`pwfrom-pollutionalerts`"
															:label="$t('pollutionalerts.pwfrom')"
															:disabled="formDisabled"
															toplabel
															clearable
															modelName="vluppollutionalertsauthorities"
															v-model="model"
															reactive
															:itemsToSelect="pwFromItemsToSelect"
															:modelFormMapping="{ idauthority: 'pwfrom' }"
															:itemValue="['idauthority']"
															:itemText="(item) => `${item.name}`"
														></pui-select>
													</v-col>
													<!-- PWTO -->
													<v-col cols="9">
														<pui-select
															:label="$t('pollutionalerts.pwto')"
															v-model="model.pwto"
															:disabled="formDisabled"
															toplabel
															clearable
															return-object
															:itemsToSelect="model.pwto"
															:items="pwtoList"
															itemValue="idauthority"
															itemText="name"
															multiple
															:order="{ idauthority: 'asc' }"
															:key="pwToKey"
														></pui-select>
													</v-col>
												</v-row>
											</pui-field-set>

											<pui-field-set :title="$t('pollutionalerts.fieldset.polwarnp1')">
												<v-row dense>
													<!-- PWDATE -->
													<v-col cols="3">
														<pui-date-field
															:id="`pwdate-pollutionalerts`"
															v-model="model.pwdate"
															:label="$t('pollutionalerts.pwdate')"
															:disabled="formDisabled"
															toplabel
															time
														></pui-date-field>
													</v-col>
												</v-row>
												<v-row>
													<!-- PWPOSITION -->
													<v-col cols="6">
														<pui-text-area
															:id="`pwposition-pollutionalerts`"
															v-model="model.pwposition"
															:label="$t('pollutionalerts.pwposition')"
															:disabled="formDisabled"
															toplabel
														></pui-text-area>
													</v-col>
													<!-- PWINCIDENT -->
													<v-col cols="6">
														<pui-text-area
															:id="`pwincident-pollutionalerts`"
															v-model="model.pwincident"
															:label="$t('pollutionalerts.pwincident')"
															:disabled="formDisabled"
															toplabel
														></pui-text-area>
													</v-col>
												</v-row>
												<v-row>
													<!-- PWOUTFLOW -->
													<v-col cols="6">
														<pui-text-area
															:id="`pwoutflow-pollutionalerts`"
															v-model="model.pwoutflow"
															:label="$t('pollutionalerts.pwoutflow')"
															:disabled="formDisabled"
															toplabel
														></pui-text-area>
													</v-col>
													<!-- PWACKNOWLEDGE -->
													<v-col cols="6">
														<pui-text-area
															:id="`pwacknowledge-pollutionalerts`"
															v-model="model.pwacknowledge"
															:label="$t('pollutionalerts.pwacknowledge')"
															:disabled="formDisabled"
															toplabel
														></pui-text-area>
													</v-col>
												</v-row>
											</pui-field-set>
										</v-col>
									</v-row>
								</v-tab-item>
								<v-tab-item :key="2" :value="'polnif'">
									<v-row class="pui-form-layout">
										<v-col cols="12">
											<pui-field-set :title="$t('pollutionalerts.fieldset.polnif')">
												<v-row dense>
													<!-- PIIDENTIFICATION -->
													<v-col cols="3">
														<pui-text-field
															:id="`piidentification-pollutionalerts`"
															v-model="model.piidentification"
															:label="$t('pollutionalerts.piidentification')"
															:disabled="formDisabled"
															toplabel
															maxlength="100"
														></pui-text-field>
													</v-col>
												</v-row>
												<v-row dense>
													<!-- PIFROM -->
													<v-col cols="3">
														<pui-select
															:id="`pifrom-pollutionalerts`"
															:attach="`pifrom-pollutionalerts`"
															:label="$t('pollutionalerts.pifrom')"
															:disabled="formDisabled"
															toplabel
															clearable
															modelName="vluppollutionalertsauthorities"
															v-model="model"
															reactive
															:itemsToSelect="piFromItemsToSelect"
															:modelFormMapping="{ idauthority: 'pifrom' }"
															:itemValue="['idauthority']"
															:itemText="(item) => `${item.name}`"
														></pui-select>
													</v-col>
													<!-- PITO -->
													<v-col cols="9">
														<pui-select
															:label="$t('pollutionalerts.pito')"
															v-model="model.pito"
															:disabled="formDisabled"
															toplabel
															clearable
															return-object
															:itemsToSelect="model.pito"
															:items="pitoList"
															itemValue="idauthority"
															itemText="name"
															multiple
															:order="{ idauthority: 'asc' }"
															:key="piToKey"
														></pui-select>
													</v-col>
												</v-row>
											</pui-field-set>
											<pui-field-set :title="$t('pollutionalerts.fieldset.polnif1')">
												<v-row dense>
													<!-- PIDATE -->
													<v-col cols="3">
														<pui-date-field
															:id="`pidate-pollutionalerts`"
															v-model="model.pidate"
															:label="$t('pollutionalerts.pidate')"
															:disabled="formDisabled"
															toplabel
															time
														></pui-date-field>
													</v-col>
												</v-row>
												<v-row dense>
													<!-- PIPOSITION -->
													<v-col cols="4">
														<pui-text-area
															:id="`piposition-pollutionalerts`"
															v-model="model.piposition"
															:label="$t('pollutionalerts.piposition')"
															:disabled="formDisabled"
															toplabel
														></pui-text-area>
													</v-col>
													<!-- PICHARACTERISTICS -->
													<v-col cols="4">
														<pui-text-area
															:id="`picharacteristics-pollutionalerts`"
															v-model="model.picharacteristics"
															:label="$t('pollutionalerts.picharacteristics')"
															:disabled="formDisabled"
															toplabel
														></pui-text-area>
													</v-col>
													<!-- PISOURCECAUSE -->
													<v-col cols="4">
														<pui-text-area
															:id="`pisourcecause-pollutionalerts`"
															v-model="model.pisourcecause"
															:label="$t('pollutionalerts.pisourcecause')"
															:disabled="formDisabled"
															toplabel
														></pui-text-area>
													</v-col>
												</v-row>
												<v-row dense>
													<!-- PIWIND -->
													<v-col cols="4">
														<pui-text-area
															:id="`piwind-pollutionalerts`"
															v-model="model.piwind"
															:label="$t('pollutionalerts.piwind')"
															:disabled="formDisabled"
															toplabel
														></pui-text-area>
													</v-col>
													<!-- PICURRENT -->
													<v-col cols="4">
														<pui-text-area
															:id="`picurrent-pollutionalerts`"
															v-model="model.picurrent"
															:label="$t('pollutionalerts.picurrent')"
															:disabled="formDisabled"
															toplabel
														></pui-text-area>
													</v-col>
													<!-- PISEA -->
													<v-col cols="4">
														<pui-text-area
															:id="`pisea-pollutionalerts`"
															v-model="model.pisea"
															:label="$t('pollutionalerts.pisea')"
															:disabled="formDisabled"
															toplabel
														></pui-text-area>
													</v-col>
												</v-row>
												<v-row dense>
													<!-- PIDRIFT -->
													<v-col cols="4">
														<pui-text-area
															:id="`pidrift-pollutionalerts`"
															v-model="model.pidrift"
															:label="$t('pollutionalerts.pidrift')"
															:disabled="formDisabled"
															toplabel
														></pui-text-area>
													</v-col>
													<!-- PIFORECAST -->
													<v-col cols="4">
														<pui-text-area
															:id="`piforecast-pollutionalerts`"
															v-model="model.piforecast"
															:label="$t('pollutionalerts.piforecast')"
															:disabled="formDisabled"
															toplabel
														></pui-text-area>
													</v-col>
													<!-- PIOBSERVERS -->
													<v-col cols="4">
														<pui-text-area
															:id="`piobservers-pollutionalerts`"
															v-model="model.piobservers"
															:label="$t('pollutionalerts.piobservers')"
															:disabled="formDisabled"
															toplabel
														></pui-text-area>
													</v-col>
												</v-row>
												<v-row dense>
													<!-- PIACTIONTAKEN -->
													<v-col cols="4">
														<pui-text-area
															:id="`piactiontaken-pollutionalerts`"
															v-model="model.piactiontaken"
															:label="$t('pollutionalerts.piactiontaken')"
															:disabled="formDisabled"
															toplabel
														></pui-text-area>
													</v-col>
													<!-- PIPHOTOS -->
													<v-col cols="4">
														<pui-text-area
															:id="`piphotos-pollutionalerts`"
															v-model="model.piphotos"
															:label="$t('pollutionalerts.piphotos')"
															:disabled="formDisabled"
															toplabel
														></pui-text-area>
													</v-col>
													<!-- PIOTHERSTATES -->
													<v-col cols="4">
														<pui-text-area
															:id="`piotherstates-pollutionalerts`"
															v-model="model.piotherstates"
															:label="$t('pollutionalerts.piotherstates')"
															:disabled="formDisabled"
															toplabel
														></pui-text-area>
													</v-col>
												</v-row>
												<v-row dense>
													<!-- PISPARE -->
													<v-col cols="4">
														<pui-text-area
															:id="`pispare-pollutionalerts`"
															v-model="model.pispare"
															:label="$t('pollutionalerts.pispare')"
															:disabled="formDisabled"
															toplabel
														></pui-text-area>
													</v-col>
													<!-- PIACKNOWLEDGE -->
													<v-col cols="4">
														<pui-text-area
															:id="`piacknowledge-pollutionalerts`"
															v-model="model.piacknowledge"
															:label="$t('pollutionalerts.piacknowledge')"
															:disabled="formDisabled"
															toplabel
														></pui-text-area>
													</v-col>
												</v-row>
											</pui-field-set>
										</v-col> </v-row
								></v-tab-item>
								<v-tab-item :key="3" :value="'polfac'">
									<v-row class="pui-form-layout">
										<v-col cols="12">
											<pui-field-set :title="$t('pollutionalerts.fieldset.polfac')">
												<v-row dense>
													<!-- PFIDENTIFICATION -->
													<v-col cols="3">
														<pui-text-field
															:id="`pfidentification-pollutionalerts`"
															v-model="model.pfidentification"
															:label="$t('pollutionalerts.pfidentification')"
															:disabled="formDisabled"
															toplabel
															maxlength="100"
														></pui-text-field>
													</v-col>
												</v-row>
												<v-row dense>
													<!-- PFFROM -->
													<v-col cols="3">
														<pui-select
															:id="`pffrom-pollutionalerts`"
															:attach="`pffrom-pollutionalerts`"
															:label="$t('pollutionalerts.pffrom')"
															:disabled="formDisabled"
															toplabel
															clearable
															modelName="vluppollutionalertsauthorities"
															v-model="model"
															reactive
															:itemsToSelect="pfFromItemsToSelect"
															:modelFormMapping="{ idauthority: 'pffrom' }"
															:itemValue="['idauthority']"
															:itemText="(item) => `${item.name}`"
														></pui-select>
													</v-col>
													<!-- PFTO -->
													<v-col cols="9">
														<pui-select
															:label="$t('pollutionalerts.pfto')"
															v-model="model.pfto"
															:disabled="formDisabled"
															toplabel
															clearable
															return-object
															:itemsToSelect="model.pfto"
															:items="pftoList"
															itemValue="idauthority"
															itemText="name"
															multiple
															:order="{ idauthority: 'asc' }"
															:key="pfToKey"
														></pui-select>
													</v-col>
												</v-row>
											</pui-field-set>
											<pui-field-set :title="$t('pollutionalerts.fieldset.polfacp3')">
												<v-row dense>
													<!-- PFDATE -->
													<v-col cols="3">
														<pui-date-field
															:id="`pfdate-pollutionalerts`"
															v-model="model.pfdate"
															:label="$t('pollutionalerts.pfdate')"
															:disabled="formDisabled"
															toplabel
															time
														></pui-date-field>
													</v-col>
												</v-row>
												<v-row dense>
													<!-- PFASSISTANCE -->
													<v-col cols="4">
														<pui-text-area
															:id="`pfassistance-pollutionalerts`"
															v-model="model.pfassistance"
															:label="$t('pollutionalerts.pfassistance')"
															:disabled="formDisabled"
															toplabel
														></pui-text-area>
													</v-col>
													<!-- PFCOST -->
													<v-col cols="4">
														<pui-text-area
															:id="`pfcost-pollutionalerts`"
															v-model="model.pfcost"
															:label="$t('pollutionalerts.pfcost')"
															:disabled="formDisabled"
															toplabel
														></pui-text-area>
													</v-col>
													<!-- PFPREARRANGEMENTS -->
													<v-col cols="4">
														<pui-text-area
															:id="`pfprearrangements-pollutionalerts`"
															v-model="model.pfprearrangements"
															:label="$t('pollutionalerts.pfprearrangements')"
															:disabled="formDisabled"
															toplabel
														></pui-text-area>
													</v-col>
												</v-row>
												<v-row dense>
													<!-- PFWHERERENDERED -->
													<v-col cols="4">
														<pui-text-area
															:id="`pfwhererendered-pollutionalerts`"
															v-model="model.pfwhererendered"
															:label="$t('pollutionalerts.pfwhererendered')"
															:disabled="formDisabled"
															toplabel
														></pui-text-area>
													</v-col>
													<!-- PFOTHERSTATES -->
													<v-col cols="4">
														<pui-text-area
															:id="`pfotherstates-pollutionalerts`"
															v-model="model.pfotherstates"
															:label="$t('pollutionalerts.pfotherstates')"
															:disabled="formDisabled"
															toplabel
														></pui-text-area>
													</v-col>
													<!-- PFCHANGECOMMAND -->
													<v-col cols="4">
														<pui-text-area
															:id="`pfchangecommand-pollutionalerts`"
															v-model="model.pfchangecommand"
															:label="$t('pollutionalerts.pfchangecommand')"
															:disabled="formDisabled"
															toplabel
														></pui-text-area>
													</v-col>
												</v-row>
												<v-row dense>
													<!-- PFEXCHANGEINFO -->
													<v-col cols="4">
														<pui-text-area
															:id="`pfexchangeinfo-pollutionalerts`"
															v-model="model.pfexchangeinfo"
															:label="$t('pollutionalerts.pfexchangeinfo')"
															:disabled="formDisabled"
															toplabel
														></pui-text-area>
													</v-col>
													<!-- PFSPARE -->
													<v-col cols="4">
														<pui-text-area
															:id="`pfspare-pollutionalerts`"
															v-model="model.pfspare"
															:label="$t('pollutionalerts.pfspare')"
															:disabled="formDisabled"
															toplabel
														></pui-text-area>
													</v-col>
													<!-- PFACKNOWLEDGE -->
													<v-col cols="4">
														<pui-text-area
															:id="`pfacknowledge-pollutionalerts`"
															v-model="model.pfacknowledge"
															:label="$t('pollutionalerts.pfacknowledge')"
															:disabled="formDisabled"
															toplabel
														></pui-text-area>
													</v-col>
												</v-row>
											</pui-field-set>
										</v-col>
									</v-row>
								</v-tab-item>
							</v-tabs-items>
						</v-col>
					</v-row>
				</v-tab-item>
				<v-tab-item :key="4" :value="'missionreport'">
					<!-- MISSIONREPORT -->
					<v-col cols="12">
						<pui-text-area
							:id="`missionreport-pollutionalerts`"
							v-model="model.missionreport"
							:label="$t('pollutionalerts.missionreport')"
							disabled
							toplabel
						></pui-text-area>
					</v-col>
				</v-tab-item>
				<v-tab-item :key="5" :value="'incidentreport'">
					<!-- INCIDENTREPORT -->
					<v-col cols="12">
						<pui-text-area
							:id="`incidentreport-pollutionalerts`"
							v-model="model.incidentreport"
							:label="$t('pollutionalerts.incidentreport')"
							disabled
							toplabel
						></pui-text-area>
					</v-col>
				</v-tab-item>
			</v-tabs-items>
			<!-- Page Footer -->
			<pui-form-footer v-if="!isModalDialog">
				<pui-form-footer-btns
					:formDisabled="formDisabled"
					:saveDisabled="saving"
					:saveAndNew="saveAndNew"
					:saveAndUpdate="saveAndUpdate"
					:save="save"
					:back="back"
				></pui-form-footer-btns>
			</pui-form-footer>
		</v-form>
		<pui-form-loading v-else></pui-form-loading>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';
import pollutionalertsActions from './PollutionalertsActions';
import pollutionalertsModals from './PollutionalertsModals.vue';

export default {
	name: 'pollutionalerts-form',
	mixins: [PuiFormMethodsMixin],
	components: {
		'pollutionalerts-modals': pollutionalertsModals
	},
	data() {
		return {
			modelName: 'pollutionalerts',
			actions: pollutionalertsActions.gridactions,
			tabmodel1: 'pollutionalerts',
			pwto: null,
			pito: null,
			pfto: null,
			pwfrom: null,
			pifrom: null,
			pffrom: null,
			pwtoList: [],
			pftoList: [],
			pitoList: [],
			pwToKey: 0,
			piToKey: 0,
			pfToKey: 0,
			shipKey: 0
		};
	},
	methods: {
		afterGetData() {
			if (this.model.dateclosure != null) {
				this.formDisabled = true;
			}
			this.pwto = this.model.pwto;
			this.pito = this.model.pito;
			this.pfto = this.model.pfto;

			this.getPwFrom();
			this.getPiFrom();
			this.getPfFrom();
			this.getPwTo();
			this.getPiTo();
			this.getPfTo();
		},
		onChangeShipId(e) {
			if (e != null && e != undefined && e.shipid != null) {
				this.model.shipid = e.shipid;
				this.model.version = e.version;
				this.shipKey++;
			}
		},
		getPwTo() {
			const opts = {
				model: 'mpollutionalertsauthorities',
				filter: {
					groupOp: 'and',
					groups: [],
					rules: []
				}
			};
			this.$puiRequests.postRequest('/puisearch', opts, (response) => {
				this.setListPwTo(response.data);
			});
		},
		setListPwTo(list) {
			this.pwtoList = [];
			this.pwtoList = list.data.map((c) => ({
				idauthority: c.idauthority,
				name: c.name
			}));

			this.pwToKey++;

			this.model.pwto = this.pwto;
		},
		getPiTo() {
			const opts = {
				model: 'mpollutionalertsauthorities',
				filter: {
					groupOp: 'and',
					groups: [],
					rules: []
				}
			};
			this.$puiRequests.postRequest('/puisearch', opts, (response) => {
				this.setListPiTo(response.data);
			});
		},
		setListPiTo(list) {
			this.pitoList = [];
			this.pitoList = list.data.map((c) => ({
				idauthority: c.idauthority,
				name: c.name
			}));

			this.piToKey++;
			this.model.pito = this.pito;
		},
		getPfTo() {
			const opts = {
				model: 'mpollutionalertsauthorities',
				filter: {
					groupOp: 'and',
					groups: [],
					rules: []
				}
			};
			this.$puiRequests.postRequest('/puisearch', opts, (response) => {
				this.setListPfTo(response.data);
			});
		},
		setListPfTo(list) {
			this.pftoList = [];
			this.pftoList = list.data.map((c) => ({
				idauthority: c.idauthority,
				name: c.name
			}));

			this.pfToKey++;
			this.model.pfto = this.pfto;
		},
		getPfFrom() {
			const opts3 = {
				model: 'pollutionalertsauthorities',
				filter: {
					groupOp: 'and',
					groups: [],
					rules: [
						{ field: 'pollutionid', op: 'eq', data: this.model.pollutionid },
						{ field: 'pollutionreportpart', op: 'eq', data: 3 },
						{ field: 'fromto', op: 'eq', data: 1 }
					]
				}
			};
			this.$puiRequests.postRequest('/puisearch', opts3, (response) => {
				this.model.pffrom = response.data.data[0].idauthority;
				this.pffrom = response.data.data[0].idauthority;
			});
		},
		getPiFrom() {
			const opts2 = {
				model: 'pollutionalertsauthorities',
				filter: {
					groupOp: 'and',
					groups: [],
					rules: [
						{ field: 'pollutionid', op: 'eq', data: this.model.pollutionid },
						{ field: 'pollutionreportpart', op: 'eq', data: 2 },
						{ field: 'fromto', op: 'eq', data: 1 }
					]
				}
			};
			this.$puiRequests.postRequest('/puisearch', opts2, (response) => {
				this.model.pifrom = response.data.data[0].idauthority;
				this.pifrom = response.data.data[0].idauthority;
			});
		},
		getPwFrom() {
			const opts = {
				model: 'pollutionalertsauthorities',
				filter: {
					groupOp: 'and',
					groups: [],
					rules: [
						{ field: 'pollutionid', op: 'eq', data: this.model.pollutionid },
						{ field: 'pollutionreportpart', op: 'eq', data: 1 },
						{ field: 'fromto', op: 'eq', data: 1 }
					]
				}
			};
			this.$puiRequests.postRequest('/puisearch', opts, (response) => {
				this.model.pwfrom = response.data.data[0].idauthority;
				this.pwfrom = response.data.data[0].idauthority;
			});
		},
		changeShipEvent(event) {
			if (event != null) {
				this.model.shipid = event.shipid;
				this.model.version = event.version;
				this.model.flag = event.flag;
				this.model.vesselname = event.shipname;
				this.model.imo = event.imo;
				this.model.callsign = event.callsign;
			} else {
				this.model.shipid = null;
				this.model.version = null;
				this.model.flag = null;
				this.model.vesselname = null;
				this.model.imo = null;
				this.model.callsign = null;
			}
		}
	},
	computed: {
		pwFromItemsToSelect() {
			return [{ idauthority: this.pwfrom }];
		},
		piFromItemsToSelect() {
			return [{ idauthority: this.model.pifrom }];
		},
		pfFromItemsToSelect() {
			return [{ idauthority: this.model.pffrom }];
		},
		tiercodeItemsToSelect() {
			return [{ tiercode: this.model.tiercode }];
		},
		itemsToSelectShipid() {
			return [{ shipid: this.model.shipid, version: this.model.version }];
		},
		flagItemsToSelect() {
			return [{ countrycode: this.model.flag }];
		},
		filteractiveShips() {
			return {
				groups: [
					{
						groupOp: 'and',
						rules: [{ field: 'inactive_ind', op: 'eq', data: 'No' }]
					}
				]
			};
		}
	},
	created() {},
	watch: {
		'model.shipid'() {
			if (this.model.shipid) {
				this.model.callsign = null;
				this.model.flag = null;
				this.model.vesselname = null;
				this.model.imo = null;
			}
		}
	}
};
</script>
